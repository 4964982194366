<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('user.user') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="5"
                :label="$t('user.search_key')"
                label-for="name"
                >
                <b-form-input
                    id="name"
                    v-model="search.search_key"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="5"
                label-for="org_id"
                :label="$t('user_role.organization')"
                >
                <b-form-select
                    plain
                    v-model="search.org_id"
                    :options="orgList"
                    :disabled="authOrgId > 0"
                    >
                    <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
              <b-form-group
                class="row"
                label-cols-sm="5"
                label-for="office_type_id"
                >
                <template v-slot:label>
                {{ $t('user.office_type')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.office_type_id"
                  :options="officeTypeList"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="office_id"
                  >
                  <template v-slot:label>
                    {{ $t('user.office')}}
                  </template>
                  <v-select name="office_id"
                    v-model="search.office_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= officeList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
            </b-col>
            <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="designation_id"
                  >
                  <template v-slot:label>
                  {{ $t('user_role.designation')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.designation_id"
                    :options="designationList"
                    id="designation_id"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
                <b-form-group
                class="row"
                label-cols-sm="5"
                :label="$t('user.nothi_user_id')"
                label-for="name"
                >
                <b-form-input
                    id="name"
                    v-model="search.nothi_user_id"
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="2">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('user.user') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-1 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(name)="data">
                      {{ data.item.name }}
                    </template>
                    <template v-slot:cell(name_bn)="data">
                      {{ data.item.name_bn }}
                    </template>
                    <template v-slot:cell(email)="data">
                      {{ data.item.email }}
                    </template>
                    <template v-slot:cell(username)="data">
                      {{ data.item.user_id }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ ($i18n.locale === 'bn') ? data.item.org_name_bn : data.item.org_name }}
                    </template>
                    <template v-slot:cell(userStatus)="data">
                      <span class="badge badge-success" v-if="data.item.userStatus == 0">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(designation_id)="data">
                      {{ getColumnName($store.state.commonObj.designationList, data.item.designation_id) }}
                    </template>
                    <template v-slot:cell(office_id)="data">
                      {{ getColumnName($store.state.commonObj.officeList, data.item.office_id) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="View" v-b-modal.modal-4 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-1 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                      <!-- <a href="javascript:" class="btn_table_action table_action_toggle" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></a> -->
                        <a href="javascript:" class="btn_table_action table_action_status" title="Click to Change Status" v-if="data.item.userStatus === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                        <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Change Status" v-if="data.item.userStatus === 1" @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="xl" :title="$t('user.user_entry')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId" :officeDesignations="officeDesignations"/>
      </p>
    </b-modal>
    <b-modal id="modal-4" size="lg" :title="$t('user.user_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-container fluid>
        <b-row>
          <b-col lg="12" sm="12">
            <iq-card>
              <template>
                <b-form>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group label-cols-sm="3" :label="$t('user_role.organization')">
                        <b-form-input v-model="user_detail.org_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.office')">
                        <b-form-input v-model="user_detail.office_name" readonly></b-form-input>
                      </b-form-group>
                      <!-- <b-form-group label-cols-sm="3" :label="$t('user.role')">
                        <b-form-input v-model="user_detail.role_name" readonly></b-form-input>
                      </b-form-group> -->
                      <b-form-group label-cols-sm="3" :label="$t('user.name')">
                        <b-form-input v-model="user_detail.name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.name_bn')">
                        <b-form-input v-model="user_detail.name_bn" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.user_id')">
                        <b-form-input v-model="user_detail.user_id" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.photo')">
                        <b-img width='100px' :src="authServiceBaseUrl+user_detail.photo" fluid alt="User Photo"></b-img>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group label-cols-sm="3" :label="$t('user.office_type')">
                        <b-form-input v-model="user_detail.office_type_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user_role.designation')">
                        <b-form-input v-model="user_detail.designation" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.secret_question')">
                        <b-form-input v-model="user_detail.question_name" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.answer')">
                        <b-form-input v-model="user_detail.answer" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('irrigation_config.phone_no')">
                        <b-form-input v-model="user_detail.phone_no" readonly></b-form-input>
                      </b-form-group>
                      <b-form-group label-cols-sm="3" :label="$t('user.email')">
                        <b-form-input v-model="user_detail.email" readonly></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import FormV from './Form'
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { userList, officeDesignationsApi, userToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        search_key: '',
        org_id: 0,
        office_id: null,
        office_type_id: 0,
        designation_id: 0
      },
      editItemId: 0,
      rows: [],
      user_detail: '',
      authServiceBaseUrl: authServiceBaseUrl,
      officeDesignations: [],
      officeTypeList: [],
      officeList: [],
      designationList: [],
      authOrgId: 0,
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left', thStyle: { width: '10%' } },
          { label: this.$t('user.namel'), class: 'text-left', thStyle: { width: '10%' } },
          { label: this.$t('user.email'), class: 'text-left', thStyle: { width: '10%' } },
          { label: this.$t('user.username'), class: 'text-left', thStyle: { width: '10%' } },
          { label: this.$t('user_role.organization'), class: 'text-left', thStyle: { width: '15%' } },
          { label: this.$t('user.office'), class: 'text-left', thStyle: { width: '15%' } },
          { label: this.$t('user_role.designation'), class: 'text-left', thStyle: { width: '15%' } },
          { label: this.$t('globalTrans.status'), class: 'text-center', thStyle: { width: '7%' } },
          { label: this.$t('globalTrans.action'), class: 'text-center', thStyle: { width: '15%' } }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
                { key: 'index' },
                { key: 'name_bn' },
                { key: 'email' },
                { key: 'user_id' },
                { key: 'org_name_bn' },
                { key: 'office_id' },
                { key: 'designation_id' },
                { key: 'userStatus' },
                { key: 'action' }
            ]
        } else {
          keys = [
                { key: 'index' },
                { key: 'name' },
                { key: 'email' },
                { key: 'user_id' },
                { key: 'org_name' },
                { key: 'office_id' },
                { key: 'designation_id' },
                { key: 'userStatus' },
                { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    },
    ...mapGetters({
      commonObj: 'commonObj',
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getParentOfficeList(newVal)
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setOfficeWiseDesignation(newVal)
      }
    }
  },
  created () {
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.search = Object.assign({}, this.search, {
        org_id: this.authOrgId
      })
    }
    this.getOfficeDesignations()
    this.loadData()
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    setOfficeWiseDesignation (officeId) {
      if (this.officeDesignations.length === 0) {
        this.designationList = []
        return
      }
      const filteredOfficeDesignations = []
      const designationList = this.$store.state.commonObj.designationList
      this.officeDesignations.forEach(element => {
        if (element.office_id === officeId) {
          const tmp = designationList.find(item => item.value === element.designation_id)
          filteredOfficeDesignations.push(tmp)
        }
      })
      this.designationList = filteredOfficeDesignations
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList
      let listObject = []
      if (orgId) {
        listObject = officeTypeList.filter(officeType => officeType.org_id === orgId)
      } else {
        listObject = officeTypeList
      }
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    getParentOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
          return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    edit (item) {
      this.editItemId = item.id
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    details (item) {
      this.user_detail = item
    },
    remove (item) {
       this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
      // this.$swal({
      //   title: this.$t('globalTrans.deleteMsg'),
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   cancelButtonText: 'No',
      //   focusConfirm: false
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.deleteData(item)
      //   }
      // })
    },
    async toggleStatus (item) {
      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      result = await RestApi.deleteData(authServiceBaseUrl, `${userToggleStatus}/${item.id}`)
      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadData()
      } else {
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(authServiceBaseUrl, userList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.formatList(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }, error => {
        if (error) {
         //
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    formatList (data) {
      try {
        const orgList = this.$store.state.orgList
        const officeTypeList = this.$store.state.commonObj.officeTypeList
        const officeList = this.$store.state.commonObj.officeList
        const designationList = this.$store.state.commonObj.designationList
        let tmpData = {}
        const listData = data.map(item => {
          tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
          const orgData = { org_name: typeof tmpData !== 'undefined' ? tmpData.text_en : '', org_name_bn: typeof tmpData !== 'undefined' ? tmpData.text_bn : '' }
          tmpData = officeList.find(officeItem => officeItem.value === item.office_id)
          const officeData = { office_name: typeof tmpData !== 'undefined' ? tmpData.text_en : '', office_name_bn: typeof tmpData !== 'undefined' ? tmpData.text_bn : '' }
          tmpData = officeTypeList.find(officeTypeItem => officeTypeItem.value === item.office_type_id)
          const officeTypeData = { office_type_name: typeof tmpData !== 'undefined' ? tmpData.text_en : '', office_type_name_bn: typeof tmpData !== 'undefined' ? tmpData.text_bn : '' }
          tmpData = designationList.find(designationItem => designationItem.value === item.designation_id)
          const designationData = { designation: typeof tmpData !== 'undefined' ? tmpData.text_en : '', designation_bn: typeof tmpData !== 'undefined' ? tmpData.text_bn : '' }
          return Object.assign({}, item, orgData, officeTypeData, officeData, designationData)
        })
        return listData
      } catch (error) {
        if (error) {
         //
        }
        return []
      }
    },
    getOfficeDesignations () {
      RestApi.getData(commonServiceBaseUrl, officeDesignationsApi, {}).then(response => {
        this.officeDesignations = response.data
      })
    }
  }
}
</script>
