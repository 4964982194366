<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <ValidationObserver ref="search"  v-slot="{ handleSubmit, reset }">
          <b-form id="search" @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row v-if="!id">
              <b-col lg="6" sm="12">
                <ValidationProvider name="Mobile" vid='mobile' rules="required|min:11|max:11">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="mobile"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{ $t('globalUserData.mobile_no')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="mobile"
                      v-model="search.mobile"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="2" sm="12" class="text-left">
                <b-button type="submit" variant="primary" class="sm">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
        <b-overlay v-if="already_applied === 1" :show="showLoading">
          <h5 class="m-auto text-center w-50 text-danger px-3 py-3 rounded my-5">
            <span>{{$t('user.admin_note') }}</span>
          </h5>
        </b-overlay>
        <iq-card v-else>
          <template v-slot:body>
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <b-overlay :show="showLoading">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Org Id" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                        >
                         <template v-slot:label>
                          {{ $t('user_role.organization')}} <span class="text-danger">*</span>
                         </template>
                        <b-form-select
                          plain
                          v-model="user.org_id"
                          :options="orgList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :disabled="authOrgId > 0"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Office Type id" vid="office_type_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="office_type_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('user.office_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="user.office_type_id"
                          :options="officeTypeList"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Office Id" vid="office_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="office_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('user.office')}} <span class="text-danger">*</span>
                        </template>
                        <v-select name="office_id"
                            v-model="user.office_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= officeList
                            :placeholder="$t('globalTrans.select')"
                            :filter-by="myFilter"
                            :state="errors[0] ? false : (valid ? true : null)"
                          />
                          <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Designation Id" vid="designation_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="designation_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('user_role.designation')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="user.designation_id"
                          :options="designationList"
                          id="designation_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Name (En)" vid="name" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('user.name')"
                        label-for="name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                        {{ $t('user.name')}} <span class="text-danger">*</span>
                         </template>
                        <b-form-input
                          v-model="user.name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="name_bn"
                        slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('user.name_bn')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="name_bn"
                          v-model="user.name_bn"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|numeric|min:11|max:11" >
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('user.mobile_no')"
                        label-for="mobile_no"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('user.mobile_no')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="mobile_no"
                          v-model="user.mobile_no"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Email" vid="email" rules="required|email|min:3">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('user.email')"
                        label-for="email"
                        slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('user.email')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="email"
                          v-model="user.email"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="User ID" vid="username" rules="required">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="username"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('user.username')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="username"
                          v-model="user.username"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Password" :rules="passwordRules()">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="password"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('user.password')}} <span v-if="!user.id" class="text-danger">*</span>
                        </template>
                        <b-form-input
                          type="password"
                          id="password"
                          v-model="user.password"
                          autocomplete="new-password"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Repeat Password" vid="password_confirmation" :rules="passwordConfirmRules()">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="password_confirmation"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('user.password_confirmation')}} <span v-if="!user.id" class="text-danger">*</span>
                        </template>
                        <b-form-input
                          type="password"
                          id="password_confirmation"
                          v-model="user.password_confirmation"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Secret Question" vid="secret_question_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="secret_question_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('user.secret_question') }}
                        </template>
                        <b-form-select
                          plain
                          v-model="user.secret_question_id"
                          :options="secretQuestionList"
                          id="secret_question_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Photo">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="photo"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('user.photo')}}
                        </template>
                        <b-img style="margin-bottom: 10px" width='100px' v-if="user.id && user.photo" :src="authServiceBaseUrl + user.photo" fluid alt="User Photo"></b-img>
                        <b-form-file
                          id="photo"
                          v-on:change="onFileChange"
                          accept="image/*"
                          v-model="user.photo"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col lg="6" sm="12">
                  <ValidationProvider name="Answer">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="answer"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                      {{ $t('user.answer')}}
                      </template>
                      <b-form-input
                        id="answer"
                        v-model="user.answer"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Organization Admin" vid="is_org_admin">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="Internal"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('user.organization_admin') }}
                          </template>
                          <b-form-checkbox
                            id="Internal"
                            v-model="user.is_org_admin"
                            :state="errors[0] ? false : (valid ? true : null)"
                            value=1
                            unchecked-value=0
                            >Yes</b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Access Movement Summary" vid="movement_summary">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="movement_summary"
                          slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{ $t('user.movement_summary') }}
                          </template>
                          <b-form-checkbox
                            id="movement_summary"
                            v-model="user.movement_summary"
                            :state="errors[0] ? false : (valid ? true : null)"
                            value=1
                            unchecked-value=2
                            >Yes</b-form-checkbox>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Supervisor" vid="supervisor_id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="supervisor_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('user_role.supervisor') }}
                        </template>
                        <b-form-select
                          plain
                          v-model="user.supervisor_id"
                          :options="userList"
                          id="supervisor_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <ValidationProvider name="Nothi User Id">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="nothi_user_id"
                        slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('user.nothi_user_id')}}
                        </template>
                        <b-form-input
                          id="nothi_user_id"
                          v-model="user.nothi_user_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
              </b-overlay>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { userStore, userUpdate, secretQuestionApi, officeUserList, userData } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  props: ['id', 'officeDesignations'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getUserData()
      this.user = tmp
    }
    this.getSecretQuestionList()
    this.getOfficeUserList()
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.authOrgId = this.authUser.org_id
      this.user = Object.assign({}, this.user, {
        org_id: this.authOrgId
      })
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.user = Object.assign({}, this.user, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.user = Object.assign({}, this.user, {
        org_id: this.authUser.office_detail.org_id
      })
    }
  },
  updated () {
  },
  data () {
    return {
      secretQList: [],
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      officeId: this.$store.state.Auth.authUser.office_id,
      showLoading: false,
      already_applied: 0,
      user: {
        name: '',
        name_bn: '',
        email: '',
        mobile_no: '',
        password: '',
        password_confirmation: '',
        username: '',
        org_id: 0,
        office_id: null,
        office_type_id: 0,
        role_id: 0,
        designation_id: 0,
        secret_question_id: 0,
        answer: '',
        photo: null,
        is_org_admin: 0,
        movement_summary: 2,
        supervisor_id: 0
      },
      search: {
        mobile: ''
      },
      authServiceBaseUrl: authServiceBaseUrl,
      upload_photo: [],
      designationList: [],
      officeTypeList: [],
      officeList: [],
      userList: [],
      authOrgId: 0,
      myFilter: (option, text, search) => {
        const temp = search.toLowerCase()
        return option.text_en.toLowerCase().indexOf(temp) > -1 ||
        option.text_bn.toLowerCase().indexOf(temp) > -1
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    secretQuestionList: function () {
      return this.secretQList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    // passwordRules: function () {
    //   return this.user.id ? '' : 'required|min:6'
    // },
    // passwordConfirmRules: function () {
    //   return this.user.id ? '' : 'required|min:6'
    // },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'user.org_id': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        // this.user = Object.assign(this.user, { office_type_id: 0, office_id: 0, designation_id: 0 })
        this.officeTypeList = this.getOfficeTypeList(newVal)
      }
    },
    'user.office_type_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.officeList = this.getParentOfficeList(newVal)
        }
    },
    'user.office_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.setOfficeWiseDesignation(newVal)
        }
    }
  },
  methods: {
    passwordRules () {
      return this.user.id ? '' : 'required|min:6'
    },
    passwordConfirmRules () {
      return this.user.id ? '' : 'required|min:6'
    },
    searchData () {
      const mobile = this.search.mobile
      this.getPersonalData(mobile)
    },
    async getPersonalData (mobile) {
      this.showLoading = true
      const params = {
        mobile: mobile
      }
      this.user.mobile = mobile
      const result = await RestApi.getData(authServiceBaseUrl, userData, params)

      if (result.success) {
        this.showLoading = false
        if (parseInt(result.data.user_type_id) !== parseInt(0)) {
          this.user.user_type_id = 0
          this.user.id = result.data.id
          this.user.name = result.data.name
          this.user.name_bn = result.data.name_bn
          this.user.email = result.data.email
          this.user.username = result.data.username
          this.user.mobile_no = result.data.mobile_no
          this.user.password = result.data.password
          this.user.password_confirmation = result.data.password
          this.already_applied = 0
        } else {
          this.already_applied = 1
        }
      } else {
        this.$refs.form.reset()
        this.showLoading = false
        this.user = {
          id: 0,
          name: '',
          name_bn: '',
          email: '',
          mobile_no: mobile,
          password: '',
          password_confirmation: '',
          username: '',
          org_id: 0,
          office_id: 0,
          office_type_id: 0,
          role_id: 0,
          designation_id: 0,
          secret_question_id: 0,
          answer: '',
          photo: null,
          is_org_admin: 0,
          movement_summary: 2,
          supervisor_id: 0
        }
      }
    },
    setOfficeWiseDesignation (officeId) {
      if (this.officeDesignations.length === 0) {
        this.designationList = []
        return
      }
      const filteredOfficeDesignations = []
      const designationList = this.$store.state.commonObj.designationList
      this.officeDesignations.forEach(element => {
        if (element.office_id === officeId) {
          const tmp = designationList.find(item => item.value === element.designation_id)
          filteredOfficeDesignations.push(tmp)
        }
      })
      this.designationList = filteredOfficeDesignations
    },
    getUserData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (e) {
      this.upload_photo = e.target.files[0]
    },
    getSecretQuestionList () {
      RestApi.getData(authServiceBaseUrl, secretQuestionApi).then(response => {
        this.secretQList = response.data
      })
    },
    getOfficeUserList () {
      RestApi.getData(authServiceBaseUrl, officeUserList + '/' + this.officeId).then(response => {
        this.userList = response.data
      })
    },
    async register () {
      this.showLoading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      var formData = new FormData()
      Object.keys(this.user).map(key => {
        if (key === 'photo') {
          formData.append(key, this.upload_photo)
        } else {
          formData.append(key, this.user[key])
        }
      })

      if (this.user.id) {
        formData.append('_method', 'PUT')
        result = await RestApi.postData(authServiceBaseUrl, `${userUpdate}/${this.user.id}`, formData, config)
      } else {
        result = await RestApi.postData(authServiceBaseUrl, userStore, formData, config)
      }

      if (result.success) {
       loadinState.listReload = true
      }

      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.showLoading = false
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.showLoading = false
      }
    },
    getDesignationList (orgId = null) {
      const designationList = this.$store.state.commonObj.designationList
      let listObject = []
      if (orgId) {
        listObject = designationList.filter(designation => designation.org_id === orgId)
      } else {
        listObject = designationList
      }
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList
      let listObject = []
      if (orgId) {
        listObject = officeTypeList.filter(officeType => officeType.org_id === orgId)
      } else {
        listObject = officeTypeList
      }
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    getParentOfficeList (officeTypeId = null) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
          return officeList.filter(office => office.office_type_id === officeTypeId)
      }
      return officeList
    }
  }
}
</script>
